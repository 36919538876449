.container-habitat {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
p {
  font-size: 1em;
  margin: 0;
}
