.conservation-animaux {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 50px auto;
  background-color: rgb(128, 132, 179);
  justify-content: space-between;
  padding: 10px;
}

.bloc-paragraphe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
}
.yellow-dark {
  background-color: rgb(227, 178, 87);
  color: black;
}
.black {
  background-color: #000;
  color: white;
}
.bloc-picture picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  margin: 10px;
  padding: 10px;
}

.bloc-ecriture {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 380px;
  height: 400px;
  margin: 10px;
}
.bloc-titre {
  font-size: 2em;
  font-weight: bold;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.photo-biberon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
  padding: 10px;
}
.photo-conservation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  padding: 10px;
}
.photo-intervention {

  width: 100px;
  height: 100px;
  padding: 10px;
 
}

