.petitrain-service {
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  margin: 40px auto;
  background-color: white;
  justify-content: space-between;
  padding: 10px;
  width: 80%;
  border-radius: 10px;
}

.brown {
  background-color: rgb(103, 85, 85);
  color: white;
}

.petit-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.petit-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
}
.petit-body-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  margin-left: 15%;
}
.petit-body-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.petit-body-right img {
  width: 70%;
  height: auto;
  border-radius: 20% 10%;
}

.little-p {
  width: 400px;
  margin: 10px auto;
  font-size: 1rem;
  margin: 10px;
}

@media only screen and (max-width: 768px) {
  .petit-body {
    flex-wrap: wrap;
  }
  .petit-body-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
  .petit-body-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .little-p {
    width: 95%;
    margin: 10px auto;
    font-size: 1rem;
  }

  .petitrain-service {
    height: 600px;
  }
  .petit-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
  }
  .petit-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
}
