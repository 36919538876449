@media only screen and (max-width: 1025px) {
  .prestation-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    max-width: 420px;
    height: auto;
    margin: 0 20px;
  }
}

@media only screen and (max-width: 1180px) {
  .prestation-card {
    margin: 0 20px;
    max-width: 380px;
  }
}
