.logo{
    display : flex;
    justify-content  :  flex-start;
    align-items: center;
}
.navbar-collapse{
    display : flex;
    justify-content  :  flex-end;
    align-items: center;
}
.logo-img{
    width : 50px;
    height : 50px;
}
.logo-title{
    font-size : 1.5rem;
    font-weight : bold;
    margin-left : 10px;
    color :#bcf271;
}
.bg-brown{
    background-color : #8B4513;
}
.dropdown-menu{
    background-color : #7d4c29;
}