.section-avis {
  background-image: url("../../../public/image/patagonie.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  margin: 40px;
  flex-wrap: wrap;
}

.avis-jumbotron {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  height: 400px;
}
.avis-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.356);
}
.avis-service {
  display: flex;
  text-align: center;
}

@media only screen and (max-width: 1025px) {
  .section-avis {
    height: auto;

    margin: 10px auto;
    height: auto;
  }

  .avis-jumbotron {
    align-items: flex-start;
    justify-content: center;
    width: 95%;
    height: auto;
    margin: 10px auto;
    flex-wrap: wrap;
  }
  .avis-title {
    margin-top: 10px;
  }
}
