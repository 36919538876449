.thumb-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 200px;
  height: 200px;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  margin: 1rem;
}
.thumb-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}
.thumb-card-header img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
}

.thumb-card a {
  text-decoration: none;
  cursor: pointer;
  color: aliceblue;
}

.thumb-card-title {
  color: black;
  text-align: center;
}
