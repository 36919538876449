.title-hight {
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.container-habitats {
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.para {
  font-size: 1rem;
  font-weight: 500;
  padding: 2%;
  margin: 2%;
}
