.first-title-formulaire-avis {
  display: grid;
    grid-template-columns:  1fr;
    grid-gap: 1em;
    margin-bottom: 1em;
    grid-template-rows: 1fr 1fr;
    background-color: rgb(167, 223, 112);
   
}





.title-formulaire-avis {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

}
.text-formulaire-pseudo {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.avis-formulaire-avis{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.liste-avis{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.soumettre{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.index{
  display:column;
  padding: 10px;
}