.animal-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 300px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--color-white);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    margin:1rem;
    border:1px solid #c7c4c4;
  }
  .animal-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
  }
  .animal-card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }