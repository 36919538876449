.prestation-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 350px;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}
.prestation-card:hover {
  transform: translateY(-10px);
}
.prestation-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}
.prestation-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.prestation-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.prestation-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 200px;
}
.prestation-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}
.prestation-card-body p {
  font-size: 1rem;
  color: #656060;
}
.button-prestation {
  justify-content: center;
  width: 80px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
}
.prestation-card a {
  text-decoration: none;
  color: #fff;
}
