.display-view-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: #f5f5f5;
}

.space-close-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background-color: #247221;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 25px;
}

.close-button:hover {
  background-color: #ff1c1c;
}
